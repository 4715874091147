import {collection, getDocs, query, where, DocumentReference, onSnapshot, Unsubscribe} from 'firebase/firestore';
import {db} from '../firebaseConfig';
import {Department, IFirestoreTraveler, ITraveler} from './interfaces';


/**
 * Fetches travelers from Firestore, optionally filtering by department.
 *
 * @param {Department} [department] - (Optional) The department to filter travelers by.
 * @returns {Promise<ITraveler[]>} A promise that resolves to an array of traveler objects.
 */
export async function fetchTravelers(department?: Department): Promise<ITraveler[]> {
  try {
    const travelersRef = collection(db, '_travelers');

    // Base query to exclude travelers with certain statuses
    let q = query(
      travelersRef,
      where('status', 'not-in', ['Installed/Delivered (completely done)', 'Canceled'])
    );

    // If a department is specified, add a filter for it
    if (department) {
      q = query(
        travelersRef,
        where('thisTravelerIsFor', '==', department),
        where('status', 'not-in', ['Installed/Delivered (completely done)', 'Canceled'])
      );
    }

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => {
      const travelerData = doc.data() as IFirestoreTraveler;

      return {
        id: doc.id,
        ref: doc.ref as DocumentReference,
        displayName: travelerData.calendarDisplayName,
        department: travelerData.thisTravelerIsFor,
        status: travelerData.status,
        events: travelerData.events, // Include the 'events' field
      } as ITraveler;
    });
  } catch (error) {
    console.error('Error fetching travelers:', error);
    return [];
  }
}

/**
 * Fetches unscheduled travelers from Firestore, filtering by department.
 * It fetches travelers where 'events' is either null or undefined by filtering client-side.
 *
 * @param {Department} department - The department to filter travelers by.
 * @param {(data: ITraveler[]) => void} onDataChange - Callback invoked with the traveler data.
 * @param {(error: Error) => void} onError - Callback invoked if there's an error.
 * @returns {Unsubscribe} A method for unsubscribing from real-time updates.
 */
export function fetchUnscheduledTravelers(
  department: Department,
  onDataChange: (data: ITraveler[]) => void,
  onError: (error: Error) => void
): Unsubscribe {
  const travelersRef = collection(db, '_travelers');

  // Define the base query constraints
  const baseConstraints = [
    where('thisTravelerIsFor', '==', department),
    where('status', 'not-in', ['Installed/Delivered (completely done)', 'Canceled']),
  ];

  // Construct the query without the 'events' filter
  const q = query(
    travelersRef,
    ...baseConstraints
    // Note: No 'events' filter here
  );

  // Set up the real-time listener
  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      // Map and filter the travelers client-side
      const travelers: ITraveler[] = querySnapshot.docs
        .map((doc) => {
          const travelerData = doc.data() as IFirestoreTraveler;

          return {
            id: doc.id,
            ref: doc.ref as DocumentReference,
            displayName: travelerData.calendarDisplayName,
            department: travelerData.thisTravelerIsFor,
            status: travelerData.status,
            events: travelerData.events, // This may be null or undefined
          } as ITraveler;
        })
        // Filter travelers where 'events' is null or undefined
        .filter((traveler) => !traveler.events); // checks for both null and undefined

      // Sort the travelers by displayName
      travelers.sort((a, b) => a.displayName.localeCompare(b.displayName));

      // Invoke the callback with the filtered and sorted travelers
      onDataChange(travelers);
    },
    (error) => {
      console.error('Error fetching unscheduled travelers:', error);
      onError(error);
    }
  );

  return unsubscribe;
}
